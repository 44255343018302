/* charts */

/* column chart */

/* panel wrapper */
#panel-column-chart {
    .container {
        position: relative;
        overflow: visible;
        left: .5rem;
        width: calc(80% - 2.5rem) !important;
        height: 100%;
        min-height: 30vh;
            #marker-one,
            #marker-two,
            #marker-three,
            #marker-four,
            #marker-five {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 1px;
            border-bottom: solid 1px $gray;
            overflow: visible;
            background: none;
            z-index: -1;
            span {
                display: block;
                p {
                    position: relative;
                    top: -1rem;
                    left: -2.5rem;
                    width: 2rem;
                    font-family: 'Antenna Condensed Light', sans-serif !important;
                    font-size: .75rem;
                    letter-spacing: .0125rem;
                    color: #888888;
                    text-align: right;
                    text-transform: uppercase;
                }
            }
        }
    }
}
/* scale */
#panel-column-chart .container #marker-one { top: 100%; }
#panel-column-chart .container #marker-two { top: 75%; }
#panel-column-chart .container #marker-three { top: 50%; }
#panel-column-chart .container #marker-four { top: 25%; }
#panel-column-chart .container #marker-five { top: 0%; }
/* calculate column width */
#panel-column-chart .grid-wrapper-min-max {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
    grid-gap: var(--grid-layout-gap);
    /* input values */
    --grid-layout-gap: 1rem;
    --grid-column-count: 10; /* overridden by an inline style. */
    --grid-item--min-width: 3rem; /* overridden by an inline style. */
    /* calculated values */
    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));
    /* full height */
    grid-template-rows: 1fr;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $gray-extra-light;
}
/* column wrapper */
#chart-column-wrapper {
    width: 100%;
    height: 100%;
}
/* column group */
.chart-column-group {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  overflow: visible;
  p {
    position: absolute;
    top: 1rem;
    margin: 0;
    width: 100%;
    font-family: 'Antenna Condensed', sans-serif !important;
    font-size: .85rem;
    letter-spacing: .025rem;
    line-height: 0;
    color: $white;
    text-align: center;
  }
  h5, h6 {
    position: absolute !important;
    top: -.125rem;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: .85rem;
    text-align: center;
  }
  h6 {
    top: calc(100% + .125rem);
  }
}
.chart-column {
  display: block;
  position: relative;
  width: 100%;
  height:  100%;
  background: none;
}
.chart-column-label {
  background: #525252;
}
.chart-column-label p {
  position: relative;
  top: 110%;
  margin: 0;
  width: 100%;
  height: 33%;
  font-family: 'Antenna Condensed Bold', sans-serif !important;
  z-index: +3;
}
/* set all columns - position absolute - height 100% */
.chart-column-value-1,
.chart-column-value-2,
.chart-column-value-3,
.chart-column-value-4,
.chart-column-value-5,
.chart-column-value-6,
.chart-column-value-7,
.chart-column-value-8,
.chart-column-value-9,
.chart-column-value-10 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: $maroon;
}
/* set all columns stack - color brick */
#chart-column-value-a2,
#chart-column-value-b2,
#chart-column-value-c2,
#chart-column-value-d2,
#chart-column-value-e2,
#chart-column-value-f2,
#chart-column-value-g2,
#chart-column-value-h2,
#chart-column-value-i2,
#chart-column-value-j2 {
  background: $brick;
}
/* letter-number - adjusts height */
/* value-number - adjusts position */
/* column one */
  #chart-column-value-a1 {
    height: 20%;
  }
  #chart-column-value-a2 {
    height: 15%;
  }
  #chart-column-value-a2.chart-column-value-2 {
    bottom: 20%;
  }
/* column two */
  #chart-column-value-b1 {
    height: 40%;
  }
  #chart-column-value-b2 {
    height: 20%;
  }
  #chart-column-value-b2.chart-column-value-2 {
    bottom: 40%;
  }
/* column two */
  #chart-column-value-c1 {
    height: 60%;
  }
  #chart-column-value-c2 {
    height: 30%;
  }
  #chart-column-value-c2.chart-column-value-2 {
    bottom: 60%;
  }
/* key */
.chart-key-wrapper {
  height: 100%;
}
p.chart-key,
#chart-key-01,
#chart-key-02 {
  display: inline;
  margin: 0 .25rem 0 0;
  width: auto;
  font-size: .8rem;
  text-transform: uppercase !important;
}
p.chart-key span {
  display: inline-block;
  margin: 0 .25rem -.175rem 0;
  width: 1rem;
  height: 1rem;
  background: $maroon;
}
#chart-key-01 p.chart-key span { background: $brick; }
#chart-key-02 p.chart-key span { background: $maroon; }

/* MEDIA QUERIES */

/* 768PX */
@media only screen and (max-width: 768px) {}
