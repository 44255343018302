/* charts base */
#panel-pie-chart .grid-item .pie-chart,
#panel-donut-chart .grid-item,
#panel-gauge-chart .grid-item  {
	figure {
		margin: 0;
	}
	figcaption span {
		display: block;
		margin: -.35rem 0 0.125rem;
		padding: 0;
		width: 100%;
	}
	figcaption span:before {
		display: inline-block;
		content: "";
		margin: 0 0.75rem -.25rem 0;
		width: 1rem;
		height: 1rem;
		border-radius: 0.125rem;
		background: currentColor;
	}
	.explainer p {
		line-height: 1.45;
	}
	span p {
		display: inline;
		margin: 0;
		font-family: 'Open Sans', sans-serif;
		font-size: .8rem;
		text-transform: uppercase;
		color: $black !important;
	}
	h5 {
		font-size: 1.85rem;
	}
}