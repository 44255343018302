.donut-chart {
  position: relative;
  margin: 0;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background: $gray-extra-light;
  figure .donut-chart > h5 {
    font-size: 1.85rem !important;
  }
  .center {
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    margin: auto;
    padding: 15% 0 0;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: $white;
  }	
  .portion-block {
    position: absolute;
    clip: rect(0px, 200px, 200px, 100px);
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .circle {
    position: absolute;
    clip: rect(0px, 100px, 200px, 0px);
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
    border-radius: 50%;
  }
}

/* donut chart one */
#donut-section-one .circle {
  background-color: $maroon;
}
#donut-section-two .circle {
  background-color: $gray;
}
#donut-section-three .circle {
  background-color: $gray-light;
}
#donut-section-four .circle {
  background-color: $gray-extra-light;
}
#donut-chart-one #donut-section-one .circle {
  animation: donut-one-first 1s 1 forwards;
}
#donut-chart-one #donut-section-two .circle {
  animation: donut-one-second 1s 1 forwards 1s;
}
#donut-chart-one #donut-section-three .circle {
  animation: donut-one-third 1s 1 forwards 2s;
}
#donut-chart-one #donut-section-four .circle {
  animation: donut-one-fourth 0.5s 1 forwards 3s;
}
#donut-chart-one #donut-section-one {
  transform: rotate(0deg);
}
#donut-chart-one #donut-section-two {
  transform: rotate(125deg);
}
#donut-chart-one #donut-section-three {
  transform: rotate(235deg);
}
#donut-chart-one #donut-section-four {
  transform: rotate(305deg);
}
/* animation */
@keyframes donut-one-first {
  from {transform: rotate(0deg);}
  to {transform: rotate(125deg);}
}	
@keyframes donut-one-second {
  from {transform: rotate(0deg);}
  to {transform: rotate(110deg);}
}	
@keyframes donut-one-third {
  from {transform: rotate(0deg);}
  to {transform: rotate(70deg);}
}
@keyframes donut-one-fourth {
  from {transform: rotate(0deg);}
  to {transform: rotate(55deg);}
}

/* donut chart - two */

#donut-chart-two #donut-section-one .circle {
  animation: donut-two-first 1s 1 forwards;
}
#donut-chart-two #donut-section-two .circle {
  animation: donut-two-second 1s 1 forwards 1s;
}
#donut-chart-two #donut-section-three .circle {
  animation: donut-two-third 1s 1 forwards 2s;
}
#donut-chart-two #donut-section-four .circle {
  animation: donut-two-fourth 0.5s 1 forwards 3s;
}
#donut-chart-two #donut-section-one {
  transform: rotate(0deg);
}
#donut-chart-two #donut-section-two {
  transform: rotate(130deg);
}
#donut-chart-two #donut-section-three {
  transform: rotate(230deg);
}
#donut-chart-two #donut-section-four {
  transform: rotate(305deg);
}
/* animation */
@keyframes donut-two-first {
  from {transform: rotate(0deg);}
  to {transform: rotate(130deg);}
}	
@keyframes donut-two-second {
  from {transform: rotate(0deg);}
  to {transform: rotate(100deg);}
}	
@keyframes donut-two-third {
  from {transform: rotate(0deg);}
  to {transform: rotate(75deg);}
}
@keyframes donut-two-fourth {
  from {transform: rotate(0deg);}
  to {transform: rotate(55deg);}
}

/* donut chart - three */

#donut-chart-three #donut-section-one .circle {
  animation: donut-three-first 1s 1 forwards;
}
#donut-chart-three #donut-section-two .circle {
  animation: donut-three-second 1s 1 forwards 1s;
}
#donut-chart-three #donut-section-three .circle {
  animation: donut-three-third 1s 1 forwards 2s;
}
#donut-chart-three #donut-section-four .circle {
  animation: donut-three-fourth 0.5s 1 forwards 3s;
}
#donut-chart-three #donut-section-one {
  transform: rotate(0deg);
}
#donut-chart-three #donut-section-two {
  transform: rotate(140deg);
}
#donut-chart-three #donut-section-three {
  transform: rotate(255deg);
}
#donut-chart-three #donut-section-four {
  transform: rotate(325deg);
}
/* animation */
@keyframes donut-three-first {
  from {transform: rotate(0deg);}
  to {transform: rotate(140deg);}
}	
@keyframes donut-three-second {
  from {transform: rotate(0deg);}
  to {transform: rotate(115deg);}
}	
@keyframes donut-three-third {
  from {transform: rotate(0deg);}
  to {transform: rotate(70deg);}
}
@keyframes donut-three-fourth {
  from {transform: rotate(0deg);}
  to {transform: rotate(35deg);}
}