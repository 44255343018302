/* charts */

/* bar chart */
/* wrappers */
#panel-bar-chart .grid-wrapper-min-max {
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap: .5rem;
}
/* chart group sections */
#chart-bar-wrapper {
  display: inline-block;
  position: relative;
  top: -.5rem;
  bottom: -.5rem;
  left: 0;
  margin: 0;
  background: none;
}
#chart-bar-wrapper {
  width: 65%;
  margin: 0;
}
/* month markers */
#marker-one,
#marker-two,
#marker-three,
#marker-four {
  position: absolute;
  top: 0rem;
  bottom: 0rem;
  width: 4px;
  background: rgba(255,255,255,.25);
  z-index: +2;
}
.marker-top, .marker-bottom {
  position: absolute;
  width: 100%;
  height: 1rem;
  background: $gray-dark;
  z-index: +2;
}
.marker-top { top: 0; left: 2px; width: 1px; }
.marker-bottom { bottom: 0; }

#marker-one { left: 0%; }
#marker-two { left: 31%; }
#marker-three { left: 62%; }
#marker-four { left: 93%; }

#marker-one p,
#marker-two p,
#marker-three p,
#marker-four p {
  position: relative;
  left: .75rem;
  font-family: 'Antenna Condensed Light', sans-serif !important;
  font-size: .75rem;
  letter-spacing: .0125rem;
  color: #888888;
  text-transform: uppercase;
}
#marker-one .marker-top p,
#marker-two .marker-top p,
#marker-three .marker-top p,
#marker-four .marker-top p {
  top: -.35rem;
}
#marker-one .marker-bottom p,
#marker-two .marker-bottom p,
#marker-three .marker-bottom p,
#marker-four .marker-bottom p {
  top: -.125rem;
}
/* chart group */
.chart-bar-group {
  display: block;
  margin: 2em 0;
  width: 100%;
  height: 3.5rem;
  font-family: 'Antenna Condensed Light', sans-serif;
  font-size: .85rem;
  line-height: 1.25;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}
.chart-bar {
  position: relative;
  width: 100%;
  height: 100%;
  background: #cccccc;
}
.chart-bar-label {
  background: #525252;
}
.chart-bar-label p,
#session-a p {
  position: relative;
  top: 110%;
  margin: 0;
  width: 100%;
  height: 33%;
  font-family: 'Antenna Condensed Bold', sans-serif !important;
  z-index: +3;
}
/* sessions */
#session-a {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: auto;
  background: $gray;
  z-index: +1;
}
/* date markers */
.session-date-marker {
  position: absolute;
  width: .33rem;
  top: 0;
  bottom: 0;
  background: $maroon;
  z-index: +1;
}
.session-date-marker.gray {
  background: $gray-dark;
}
/* session specific - duration bars */
#session-a {
  left: 19%;
  width: 39%;
}
/* session specific - marker */
#charts-grid-wrapper.charts-grid-wrapper-padding #marker-one .marker-top p {
  top: 0rem;
}
/* session specific - anchor - wrapper */
#a-session-anchor #charts-wrapper {
  margin: 0;
  width: 100%;
}
/* a session specific - date markers */
#a-date-one { left: 0; }
#a-date-two { left: 2%; }
#a-date-three { left: 10%; }
#a-date-four { left: 16%; }
#a-date-five { left: 29%; }
#a-date-six { left: 83%; }
#a-date-seven { left: 100%; }
/* chart-bar-wrapper override */
#chart-bar-wrapper.chart-bar-override #marker-one {
  width: 1px;
  background: $gray-dark;
  p {
    top: -.75rem;
    left: .5rem;
  }
  span {
    left: 0;
  }
}
#chart-bar-wrapper.chart-bar-override .chart-bar-group {
  .session-date-marker {
    background: none;
  }
  p {
    position: absolute;
    right: .5rem;
    top: 50%;
    margin: 0;
    font-family: 'Antenna Condensed Bold', sans-serif !important;
    letter-spacing: .025rem;
    line-height: 0;
    color: $white;
  }
  margin: 1rem 0 0;
}
.chart-bar.background-none {
  background: none;
}
.chart-bar-value-1,
.chart-bar-value-2 {
  position: relative;
  width: 100%;
  height: 100%;
  background: $maroon;
}
.chart-bar-value-2 {
  position: absolute;
  top: 0;
  left: 0;
  background: $brick;
}
#chart-bar-value-a1 {
  left: 22%;
  width: 52%;
}
#chart-bar-value-a2 {
  width: 22%;
}
#chart-bar-value-b1 {
  left: 36%;
  width: 54%;
}
#chart-bar-value-b2 {
  width: 36%;
}
#chart-bar-value-c1 {
  left: 28%;
  width: 42%;
}
#chart-bar-value-c2 {
  width: 28%;
}
.chart-key-wrapper {
  width: 100%;
}
p.chart-key,
#chart-key-01,
#chart-key-02 {
  display: inline;
  margin: 0 .25rem 0 0;
  width: auto;
  font-size: .8rem;
  text-transform: uppercase !important;
}
p.chart-key span {
  display: inline-block;
  margin: 0 .25rem -.175rem 0;
  width: 1rem;
  height: 1rem;
  background: $maroon;
}
#chart-key-01 p.chart-key span { background: $brick; }
#chart-key-02 p.chart-key span { background: $maroon; }

/* MEDIA QUERIES */

/* 768PX */
@media only screen and (max-width: 768px) {}
